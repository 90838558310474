import _get from 'lodash/get'
import _mergeWith from 'lodash/mergeWith'
import PropTypes from 'prop-types'
import { useEffect, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import ReactSelect, { components } from 'react-select'
import Creatable from 'react-select/creatable'
import { forkJoin } from 'rxjs'
import api from '../../api'
import useModals from '../../hooks/useModals'
import { errorHandler } from '../../utils/errors'
import Option from './Option'
import { getStyles, theme } from './styles'

const CrossIcon = components.CrossIcon

const limit = 25

const customizer = (...args) =>
  args.slice(0, 2).every((obj) => Array.isArray(obj)) ? args[1] : undefined

const getDefaultValue = (defaultValue) => {
  if (
    defaultValue?.constructor === Object ||
    [null, undefined].includes(defaultValue)
  ) {
    return defaultValue
  }

  if (Array.isArray(defaultValue)) {
    return defaultValue.map(getDefaultValue)
  }

  return { label: defaultValue, value: defaultValue }
}

const reducer = (state, { type, ...data }) => {
  switch (type) {
    case 'SET_VALUES': {
      const nextState = { ...state }

      if (data.serviceOptions && !nextState.selectKey) {
        nextState.selectKey = Math.random()
      }

      _mergeWith(nextState, data, customizer)

      return nextState
    }

    default:
      throw new Error(`Invalid action type: "${type}"`)
  }
}

const Select = ({
  defaultValue,
  fromService,
  isDefault,
  isFixed,
  isValidNewOption,
  onChange,
  options = [],
  removeOption,
  styleOpts = {},
  ...props
}) => {
  const { addModal, removeModal } = useModals()
  const { t } = useTranslation()

  const [state, dispatch] = useReducer(reducer, {
    append: false,
    defaultValue: getDefaultValue(defaultValue),
    isLoading: false,
    queryParams: {
      client_id: fromService?.client_id,
      distinct: fromService?.distinct,
      doi_link: fromService?.doi_link,
      filters: fromService?.filters,
      ignoreLoadingState: true,
      limit,
      sort: fromService?.sort,
      searchterm: '',
      skip: 0,
      test: fromService?.test
    },
    serviceOptions: [],
    total: Number.POSITIVE_INFINITY
  })

  const Component = props.isCreatable ? Creatable : ReactSelect

  const handleInput = (searchterm, { action }) => {
    if (action === 'input-change') {
      dispatch({
        type: 'SET_VALUES',
        append: false,
        queryParams: { searchterm, skip: 0 },
        total: Number.POSITIVE_INFINITY
      })
    }
  }

  const handleOnChange = (...args) => {
    dispatch({
      type: 'SET_VALUES',
      queryParams: { searchterm: '' }
    })

    if (onChange) {
      onChange(...args)
    }
  }

  const loadMore = () => {
    if (state.total > state.queryParams.skip) {
      dispatch({
        type: 'SET_VALUES',
        append: true,
        queryParams: {
          skip: state.queryParams.skip + limit
        }
      })
    }
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    const service = api[fromService?.name] || { defaults: {} }
    const label = service.defaults.label
    const primaryKey = service.defaults.primaryKey

    const sources = {}

    const getLabel = (item) => {
      const key = label
      return typeof key === 'function' ? key(item) : _get(item, key)
    }

    if (fromService && state.total > state.queryParams.skip) {
      sources.items = service.find$(
        state.queryParams,
        fromService.args,
        fromService.includes
      )
    }

    const defaultOption = state.serviceOptions.find(
      ({ value }) => value === defaultValue
    )

    if (defaultOption) {
      dispatch({ type: 'SET_VALUES', defaultValue: defaultOption })
    }

    if (
      fromService &&
      !['', null, undefined].includes(defaultValue) &&
      (Array.isArray(defaultValue)
        ? defaultValue.some((v) => typeof v === 'number')
        : defaultValue?.constructor !== Object)
    ) {
      sources.defaultValue = service.findAll$(
        {
          ignoreLoadingState: true,
          filters: {
            [primaryKey]: Array.isArray(defaultValue)
              ? defaultValue
              : [defaultValue]
          }
        },
        fromService.args,
        fromService.includes
      )
    }

    if (Object.keys(sources).length > 0) {
      dispatch({ type: 'SET_VALUES', isLoading: true })
    }

    const sub = forkJoin(sources).subscribe((sources) => {
      const nextState = { type: 'SET_VALUES', isLoading: false }

      if (sources.items) {
        const serviceOptions = [
          ...options,
          ...sources.items.data
            .filter((item) => getLabel(item) !== undefined)
            .map((item) => {
              const id = !fromService.noLabelIds && item[primaryKey]

              const option = {
                label:
                  fromService.label?.(item) ??
                  `${getLabel(item)}${id ? ` (${id})` : ''}`,
                value: item[fromService.key]
              }

              if (fromService.extraAttrs) {
                for (const attr of fromService.extraAttrs) {
                  option[attr] = item[attr]
                }
              }

              if (fromService.getDisabled) {
                option.isDisabled = fromService.getDisabled(item)
              }

              return option
            })
        ]

        nextState.serviceOptions = state.append
          ? [...state.serviceOptions, ...serviceOptions]
          : serviceOptions

        nextState.total = sources.items.total
      }

      if (sources.defaultValue) {
        nextState.defaultValue =
          sources.defaultValue.length === 1
            ? {
                label: sources.defaultValue.data[0][label],
                value: sources.defaultValue.data[0][primaryKey]
              }
            : sources.defaultValue.data.map((s) => {
                const _label = typeof label === 'function' ? label(s) : s[label]
                return {
                  label: `${_label} (${s[primaryKey]})`,
                  value: s[primaryKey]
                }
              })
      }

      return dispatch(nextState)
    }, errorHandler)

    return () => sub.unsubscribe()
    // (see c48a9cfb)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    defaultValue,
    state.queryParams,
    state.queryParams.filters,
    state.queryParams.searchterm,
    state.queryParams.skip
  ])

  useEffect(() => {
    if (fromService) {
      dispatch({
        type: 'SET_VALUES',
        queryParams: { filters: fromService.filters }
      })
    }
  }, [fromService])

  const options_ = fromService ? state.serviceOptions : options

  return (
    <Component
      backspaceRemovesValue={!isDefault && !isFixed}
      classNamePrefix={props.classNamePrefix || props.name}
      captureMenuScroll={true}
      components={{
        MultiValueRemove: ({ children, data, innerProps }) => {
          if (isFixed?.(data)) return null

          if (isDefault?.(data)) {
            const handler = innerProps.onClick
            innerProps.onClick = () => {
              const id = addModal({
                actions: [
                  {
                    action: () => removeModal(id),
                    color: 'secondary',
                    label: t('misc.cancel')
                  },
                  { action: handler, label: t('misc.ok') }
                ],
                body: t('misc.confirm_default_option_removal', {
                  name: data.label
                }),
                title: t('misc.options')
              })
            }
          }

          return (
            // biome-ignore lint/a11y:
            <div role="button" {...innerProps}>
              {children || <CrossIcon size={14} />}
            </div>
          )
        },
        Option: (props) => (
          <Option
            isLoading={state.isLoading}
            removeOption={props.isDisabled ? undefined : removeOption}
            {...props}
          />
        )
      }}
      defaultValue={state.defaultValue}
      formatCreateLabel={(value) => t('misc.select_create', { value })}
      inputValue={fromService ? state.queryParams.searchterm : undefined}
      isLoading={state.isLoading}
      isValidNewOption={
        isValidNewOption ??
        ((value) => {
          if (!value) return false
          const r = /\s\(\d+\)$/
          return !options_.find?.(
            (o) => (o.label ?? o).replace?.(r, '') === value
          )
        })
      }
      key={state.selectKey}
      loadingMessage={() => t('misc.loading')}
      onMenuScrollToBottom={fromService && loadMore}
      noOptionsMessage={() => t('misc.not_found')}
      onChange={handleOnChange}
      onInputChange={fromService && handleInput}
      options={options_}
      placeholder={
        fromService
          ? t('misc.input_placeholder_search')
          : t('misc.please_select')
      }
      styles={getStyles({ ...styleOpts, isLoading: state.isLoading })}
      theme={theme}
      {...props}
    />
  )
}

Select.propTypes = {
  fromService: PropTypes.shape({
    args: PropTypes.object,
    client_id: PropTypes.number,
    distinct: PropTypes.string,
    doi_link: PropTypes.bool,
    extraAttrs: PropTypes.arrayOf(PropTypes.string),
    filters: PropTypes.object,
    getDisabled: PropTypes.func,
    includes: PropTypes.array,
    label: PropTypes.func,
    name: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    sort: PropTypes.shape({
      desc: PropTypes.bool,
      key: PropTypes.string
    }),
    test: PropTypes.oneOfType([PropTypes.bool, PropTypes.number])
  }),
  isCreatable: PropTypes.bool,
  isDefault: PropTypes.func,
  isFixed: PropTypes.func,
  onChange: PropTypes.func,
  options: PropTypes.array,
  removeOption: PropTypes.func,
  styleOpts: PropTypes.object
}

export default Select
